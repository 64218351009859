<template>
    <div>
        <topsearch :searchForm.sync="listParams" @submit="getList" />
        <div class="table">
            <!-- <div class="top">
                <p>
                    <span>已选择</span>
                    <span style="color: #0062FF;">1</span>
                    <span>份合同</span>
                </p>
                <el-button type="primary" plain size="small">下载</el-button>
            </div> -->
            <div class="tableBox">
                <etable :list="list" @operate="operate" />
                <hdqPagination ref="pagination" small layout="prev, pager, next" @change="getList" />
            </div>
            <dirDialog ref="dirDialog" @success="getList" />
            <insuranceDialog ref="insuranceDialog" @success="getList" />
        </div>
    </div>
</template>

<script>
import topsearch from "./topsearch.vue"
import list from "@/views/ElectronicDocuments/mixins/list.js"
export default {
    mixins: [list],
    components: {
        topsearch
    },
    data() {
        return {

            listParams: {
                keyword: '',
                state: '',   //合同状态
                insuranceState: null,  //合同保状态
                initiated: null,   //合同时间
            }
        }
    },
    mounted() {
        // this.getList()
    },


    watch: {
        "$route.params": {
            handler() {
                this.$nextTick(() => {
                    this.getList()
                })
            },
            immediate: true
        }
    }
}

</script>

<style lang="scss" scoped>
.table {
    background: #fff;
    padding: 20px;

    margin-top: 16px;

    .top {
        height: 60px;
        display: flex;
        align-items: center;

        p {
            color: #666;
            font-family: "Source Han Sans CN";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin-right: 20px
        }
    }

}
</style>