<template>

    <div class="bg">
        <div class="top">
            <div class="title">全部合同</div>
        </div>
        <div class="row">
            <div class="lable">合同状态：</div>
            <div class="main">
                <zhuangtai @choose="choose({ value: '' })" style="margin-right: 10px;" :active="searchForm.state === ''"
                    text="全部" :value="''" />
                <zhuangtai @choose="choose(item)" style="margin-right: 10px;" v-for="item in contractStateList"
                    :active="item.value === searchForm.state" :key="item.value" :text="item.label"
                    :value="item.value" />
            </div>
        </div>
        <div class="b_row">
            <div class="col">
                <div class="lable">合同名称：</div>
                <div class="input">
                    <el-input placeholder="请输入内容" size="mini" v-model="searchForm.keyword" clearable></el-input>
                </div>
            </div>
            <div class="col">
                <div class="lable">合同保状态：</div>
                <div class="input">
                    <el-select v-model="searchForm.insuranceState" size="mini" style="width:100%;"
                        placeholder="请选择合同保状态" @keyup.enter.native="onSubmit" clearable>
                        <el-option v-for="item in insuranceState" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="col">
                <div class="lable">发起日期：</div>
                <div class="input">
                    <el-date-picker v-model="searchForm.initiated" type="daterange" range-separator="至" start-placeholder="开始日期"
                        end-placeholder="结束日期" value-format="timestamp">
                    </el-date-picker>
                </div>
            </div>
            <!-- <div class="col">
                <div class="lable">合同保：</div>
                <div class="input">
                    <el-input placeholder="请输入内容" size="mini"></el-input>
                </div>
            </div> -->
            <div class="col last">
                <el-button type="primary" style="width: 78px;" icon="el-icon-search" size="mini" @click="onSubmit">搜索</el-button>
                <el-button size="mini" style="width: 78px;">重置</el-button>
            </div>
        </div>
    </div>

</template>

<script>
import zhuangtai from "@/views/ElectronicDocuments/components/zhuangtai.vue";
import selectData from "@/mixins/selectData.js"
export default {
    mixins: [selectData],
    props: {
        searchForm: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        zhuangtai,
    },
    data() {
        return {

        }
    },
    methods: {
        choose(res) {
            this.$emit('update:searchForm', {
                ...this.searchForm,
                state: res.value
            })
        },
        onSubmit() {
            this.$emit('submit')
        },
    }
}

</script>

<style lang="scss" scoped>
.bg {
    background: #fff;
    height: 220px;
    border-radius: 6px;
    padding: 0 20px;

    .title {
        color: #262626;
        font-family: "Source Han Sans CN";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 60px;

    }

    .row {
        height: 50px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .main {
            display: flex;
        }

    }

    .b_row {
        height: 100px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .col {
            height: 50px;
            display: flex;
            align-items: center;
            width: calc((100% - (40px * 2))/3);
            margin-right: 40px;
        }

        .col:nth-child(3n) {
            margin-right: 0px;
        }

        .last {
            // justify-content: flex-end;
        }
    }

    .lable {
        color: #666;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        width: 100px;
        text-align: end;
    }

    .input {
        width: calc(100% - 120px)
    }
}
</style>