<template>
    <div class="zhuangtaibox" @click="choose">
        <div :class="active ? 'active' : 'unActive'" class="default">{{ text }}</div>
        <svg v-if="active" class="svg" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
            fill="none">
            <path d="M0 14L14 0V12C14 13.1046 13.1046 14 12 14H0Z" fill="#0062FF" />
            <path d="M7.5 9.5L9 11L12 8" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <svg v-else class="svg" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
            fill="none">
            <path d="M0 14L14 0V12C14 13.1046 13.1046 14 12 14H0Z" fill="#EEEEEE" />
            <path d="M7.5 9.5L9 11L12 8" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        active: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: '选项'
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {

        };
    },
    methods: {
        choose() {
            this.$emit('choose', this.value)
        }
    }
}
</script>

<style lang="scss" scoped>
.zhuangtaibox {
    position: relative;
    cursor: pointer;
    .default {
        width: 66px;
        flex-shrink: 0;
        border-radius: 2px;
        font-family: "Source Han Sans CN";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        text-align: center;
    }

    .active {
        border: 1px solid #0062FF;
        color: #0062FF;
    }

    .unActive {
        color: #262626;
        border: 1px solid #EEE;
    }

    .svg {
        position: absolute;
        right: 0px;
        bottom: 0px;
    }
}
</style>